import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import LogoWhite from "../../assets/images/logo-black.png";
import Custom from "../../assets/images/custom.png";
import { FaPlane } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import LogoBlack from "../../assets/images/logo-black.png";
import Plane from "../../assets/images/plane.png";
import { MdOutlineClose } from "react-icons/md";
import { PiPhoneCallFill } from "react-icons/pi";

function Search() {
  const { searchID } = useParams();
  const [airplane, setAirplane] = useState("0");

  useEffect(() => {
    console.log(window.location.search);
    let startingTimer = setTimeout(() => {
      setAirplane((prev) => prev + 50);
    }, 1000);
    let finishTimer = setTimeout(() => {
      setAirplane((prev) => prev + 50);
      setTimeout(() => {
        window.location.href = `https://www.aviasales.com/search/${searchID}${window.location.search}`;
      });
    }, 8000);

    return () => {
      clearTimeout(startingTimer);
      clearTimeout(finishTimer);
    };
  }, []);

  return (
    <div className="flex flex-col items-center px-8">
      <div className="w-[700px] py-7 max-md:w-full">
        <div className="flex items-center justify-between">
          <img
            src={LogoWhite}
            alt=""
            className="w-44"
            style={{
              mixBlendMode: "difference",
            }}
          />
          <div className="flex items-center">
            <a
              href="tel:+18338352670"
              className="bg-[#fc6c3a] py-3 px-5 rounded-md text-white text-lg flex items-center justify-center"
            >
              <img src={Custom} className="w-9" alt="" />
              <span className="max-md:hidden font-bold ml-2">
                Toll Free +1833-835-2670
              </span>
            </a>
          </div>{" "}
        </div>
        <div className="flex flex-col items-center justify-center h-96 w-full">
          <div className="mb-8 w-full">
            <div className="text-2xl font-bold text-center">
              Searching for flights
            </div>
            <div className="my-10 w-full">
              <div className="w-full relative flex items-center">
                <div
                  className="w-2/3 bg-[#fc6c3a] h-1 relative flex items-center justify-end"
                  style={{
                    width: `${airplane}%`,
                    transition: "2s all ease-in-out",
                  }}
                >
                  <div className="absolute right-0 ">
                    <FaPlane size={36} />
                  </div>
                </div>
                <div className="absolute -right-4 bottom-1">
                  <FaLocationDot size={28} />
                </div>
              </div>
            </div>
          </div>

          <div className="w-4/5 text-center relative  shadow-md py-6 rounded-md bg-[#fc6c3a]">
            <div className="py-2 px-7 rounded-lg bg-[white] -top-3 left-3 border border-black/70 absolute text-black">
              Call now
            </div>
            <div className="text-black/80 font-semibold text-lg pt-4">
              Get unbelievable prices to all Destinations
            </div>
            <div className="text-5xl font-bold text-black max-sm:text-2xl">
              +1833-835-2670
            </div>
          </div>
          <div className="fixed w-full h-full flex max-md:items-start items-center justify-center z-50">
            <div className="absolute w-full h-full bg-white/60"></div>
            <div className="max-md:w-[550px] w-11/12 max-md:h-[550px] relative max-sm:w-11/12 flex items-center justify-start flex-col ">
              <div className="absolute bg-black -right-5 top-2 flex items-center justify-center rounded-full z-50 w-8 h-8">
                <MdOutlineClose color="white" />
              </div>
              <a
                href="tel:+18338352670"
                className="relative z-10 h-full bg-[#ffefea] w-full mt-4 rounded-lg py-4"
              >
                <div className="flex items-center justify-between  px-4 ">
                  <img src={LogoBlack} alt="" className="w-24" />
                  <div>
                    <div className="w-full bg-white h-full  rounded-full flex items-center justify-center pr-3 border-y border-y-black/50 border-r border-r-black/50">
                      <div className="bg-[#fc6c3a] w-9 h-9 flex items-center justify-center rounded-full">
                        <div className="call_icon">
                          <PiPhoneCallFill
                            color="white"
                            size={22}
                            id="call_icon"
                          />
                        </div>
                      </div>
                      <div className="ml-3  h-full">
                        <div className="text-black font-bold text-xs">
                          +1833-835-2670
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full py-2 bg-black my-4 px-4 text-center text-white/70">
                  Get Discounted Flights on Call
                </div>
                <div className="flex items-center justify-between px-5">
                  <img src={Plane} alt="" className="w-48" />
                  <div className="flex flex-wrap max-md:flex-col">
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Delta_logo.svg/768px-Delta_logo.svg.png"
                      alt=""
                      className="mb-4 w-52 max-md:w-24"
                    />
                    <img
                      src="https://upload.wikimedia.org/wikipedia/en/thumb/e/e0/United_Airlines_Logo.svg/450px-United_Airlines_Logo.svg.png"
                      alt=""
                      className="mb-4 w-52 max-md:w-24"
                    />
                    <img
                      src="https://upload.wikimedia.org/wikipedia/en/thumb/2/23/American_Airlines_logo_2013.svg/450px-American_Airlines_logo_2013.svg.png"
                      alt=""
                      className="mb-4 w-52 max-md:w-24"
                    />
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Southwest_Airlines_logo_2014.svg/330px-Southwest_Airlines_logo_2014.svg.png"
                      alt=""
                      className="mb-4 w-52 max-md:w-24"
                    />
                  </div>
                </div>
                <div className="flex items-center w-full flex-wrap px-4 mt-3">
                  <div className="flex w-1/2 pr-2 mb-2 ">
                    <div className="w-full bg-[#fc6c3a] py-2 text-sm font-semibold rounded-full text-center">
                      New booking
                    </div>
                  </div>
                  <div className="flex w-1/2 pl-2 mb-2 ">
                    <div className="w-full bg-[#fc6c3a] py-2 text-sm font-semibold rounded-full text-center">
                      Changes
                    </div>
                  </div>
                  <div className="flex w-1/2 pr-2 mb-2 ">
                    <div className="w-full bg-[#fc6c3a] py-2 text-sm font-semibold rounded-full text-center">
                      Cancellation
                    </div>
                  </div>
                  <div className="flex w-1/2 pl-2 mb-2 ">
                    <div className="w-full bg-[#fc6c3a] py-2 text-sm font-semibold rounded-full text-center">
                      Custom services
                    </div>
                  </div>
                </div>
                <div className="w-full mt-6   px-4 text-start text-black/70">
                  Get 24/7 assistance
                </div>
                <div className="flex items-center justify-center mt-2 px-5">
                  {/* <img
                src={Booking}
                alt=""
                className="w-32 h-32 rounded-full obje"
              /> */}
                </div>
                <div className="w-full flex items-center justify-center mt-6   px-4 text-start text-black/70">
                  <div className="">
                    <div className="w-full bg-[#fc6c3a] h-full  rounded-full flex items-center justify-center pr-3 border-y border-y-black/50 border-r border-r-black/50">
                      <div className=" w-16 h-16 bg-white flex items-center justify-center rounded-full">
                        <div className="call_icon">
                          <PiPhoneCallFill
                            color="#fc6c3a"
                            size={28}
                            id="call_icon"
                          />
                        </div>
                      </div>
                      <div className="ml-3  h-full font-bold text-black text-lg">
                        <div className="text-black text-xs font-thin">
                          Call now and book
                        </div>
                        +1833-835-2670
                      </div>
                    </div>{" "}
                  </div>
                </div>
                <div className="font-bold text-center mt-3">
                  toll free call and 24/7 helplines{" "}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Search;
